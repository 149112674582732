import React from "react";
import PathChain from "../components/PathChain";
import { Helmet } from "react-helmet";

const TermsCondition = () => {
  return (
    <div className="flex justify-center">
       <Helmet>
        <meta charSet="utf-8" />
        <title>
          Terms & Conditions - Chahat Namkeen
        </title>
      </Helmet>
      <div className="px-4 py-8 lg:pb-20 w-full lg:w-11/12 xl:w-5/6">
        <PathChain />
        <p className="mb-4 mt-10 ">
          Thank you for visiting{" "}
          <u className="normal-case">
            https://chahat-git-main-gdivyeshns-projects.vercel.app
          </u>{" "}
          (Chahat Namkeen) website. These Terms and Conditions govern your use
          of our website. By accessing or using our website, you agree to be
          bound by these Terms and Conditions. If you do not agree with any part
          of these Terms and Conditions, you may not use our website.
        </p>

        <h2 className="text-lg font-semibold mb-2">1. Use of Website</h2>
        <ul className="list-disc pl-8 mb-4">
          <li>
            You must be at least 18 years old and capable of forming a binding
            contract to use our website.
          </li>
          <li>
            You agree to use our website only for lawful purposes and in a
            manner that does not infringe the rights of, restrict or inhibit
            anyone else's use and enjoyment of the website.
          </li>
        </ul>

        <h2 className="text-lg font-semibold mb-2">2. Intellectual Property</h2>
        <ul className="list-disc pl-8 mb-4">
          <li>
            The content on our website, including but not limited to text,
            graphics, logos, images, and software, is the property of the
            Company and is protected by copyright laws.
          </li>
          <li>
            You may not modify, copy, distribute, transmit, display, perform,
            reproduce, publish, license, create derivative works from, transfer,
            or sell any information, software, products, or services obtained
            from our website without our prior written consent.
          </li>
        </ul>

        <h2 className="text-lg font-semibold mb-2">3. Disclaimer of Warranties</h2>
        <p className="mb-4">
          Our website is provided "as is" and "as available" without warranties
          of any kind, either express or implied, including, but not limited to,
          implied warranties of merchantability, fitness for a particular
          purpose, or non-infringement.
        </p>

        <h2 className="text-lg font-semibold mb-2">4. Limitation of Liability</h2>
        <p className="mb-4">
          In no event shall the Company, its officers, directors, employees, or
          agents be liable to you or any third party for any indirect,
          consequential, exemplary, incidental, special, or punitive damages,
          including lost profit damages arising from your use of our website.
        </p>

        <h2 className="text-lg font-semibold mb-2">5. Governing Law</h2>
        <p className="mb-4">
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of India, without regard to its conflict of
          law principles.
        </p>

        <h2 className="text-lg font-semibold mb-2">
          6. Changes to Terms and Conditions
        </h2>
        <p className="mb-4">
          We reserve the right to update or change these Terms and Conditions at
          any time without prior notice. Any changes will be posted on this page
          with an updated effective date.
        </p>

        <h2 className="text-lg font-semibold mb-2">7. Contact Us</h2>
        <p className="mb-4">
          If you have any questions about these Terms and Conditions, please
          contact us at thechahatnamkeen@gmail.com.
        </p>
      </div>
    </div>
  );
};

export default TermsCondition;
